// Global packages and components
// @REF: https://www.sanity.io/docs/portable-text-to-react
// @NOTE: Types are a bit confusing here, so using "any" for now
import { Link } from '@remix-run/react'
import { PortableText } from '@portabletext/react'
import { headingSizes } from '~/components/partials/common/heading'

// Types
import type { PortableTextBlock } from '@portabletext/react'

interface ContentEditorProps {
  content?: PortableTextBlock[]
}

// Main export
const ContentEditor = ({ content }: ContentEditorProps) => {
  if (!content) return null

  const serializers = {
    types: {
      image: ({ value }: any) => {
        if (!value?.asset?.url) return null

        return (
          <img
            src={value.asset.url}
            alt={value?.alt}
            className="w-full"
          />
        )
      },
    },
    block: {
      blockquote: ({ children }: any) => {
        return (
          <blockquote className="relative mb-4 pl-4 text-lg italic before:absolute before:left-0 before:top-0 before:h-full before:border-l-2 before:border-vibrant-green">
            {children}
          </blockquote>
        )
      },
      h1: ({ children }: any) => {
        return <h1 className="font-program">{children}</h1>
      },
      h2: ({ children }: any) => {
        return <h2 className="font-program">{children}</h2>
      },
      h3: ({ children }: any) => {
        return <h3 className="font-program">{children}</h3>
      },
      h4: ({ children }: any) => {
        return <h4 className="font-program">{children}</h4>
      },
      h5: ({ children }: any) => {
        return <h5 className="font-program">{children}</h5>
      },
      h6: ({ children }: any) => {
        return <h6 className="font-program">{children}</h6>
      },
      normal: ({ children }: any) => {
        return <p>{children}</p>
      },
      normalSm: ({ children }: any) => {
        return <p className="text-sm">{children}</p>
      },
      normalLg: ({ children }: any) => {
        return <p className="text-lg">{children}</p>
      },
    },
    marks: {
      strong: ({ children }: any) => {
        return <span className="font-bold">{children}</span>
      },
      semibold: ({ children }: any) => {
        return <span className="font-semibold">{children}</span>
      },
      uppercase: ({ children }: any) => {
        return <span className="uppercase">{children}</span>
      },
      textWhite: ({ children }: any) => {
        return <span className="text-white">{children}</span>
      },
      textGrey: ({ children }: any) => {
        return <span className="text-gray-500">{children}</span>
      },
      textDark: ({ children }: any) => {
        return <span className="text-gray-900">{children}</span>
      },
      textForestGreen: ({ children }: any) => {
        return <span className="text-forest-green">{children}</span>
      },
      textTealGreen: ({ children }: any) => {
        return <span className="text-teal-green">{children}</span>
      },
      textVibrantGreen: ({ children }: any) => {
        return <span className="text-vibrant-green">{children}</span>
      },
      sizeH1: ({ children }: any) => {
        return <span className={headingSizes.h1}>{children}</span>
      },
      sizeH2: ({ children }: any) => {
        return <span className={headingSizes.h2}>{children}</span>
      },
      sizeH3: ({ children }: any) => {
        return <span className={headingSizes.h3}>{children}</span>
      },
      sizeH4: ({ children }: any) => {
        return <span className={headingSizes.h4}>{children}</span>
      },
      sizeH5: ({ children }: any) => {
        return <span className={headingSizes.h5}>{children}</span>
      },
      sizeH6: ({ children }: any) => {
        return <span className={headingSizes.h6}>{children}</span>
      },
      sizeH7: ({ children }: any) => {
        return <span className={headingSizes.h7}>{children}</span>
      },
      sizeH8: ({ children }: any) => {
        return <span className={headingSizes.h8}>{children}</span>
      },
      internalLink: ({ value, children }: any) => {
        let link = '/'

        if (!value?.slug?.current) {
          // "Fixed" documents in Sanity
          link =
            value?.reference?._ref == 'hire'
              ? link + 'hire'
              : value?.reference?._ref == 'buy'
                ? link + 'buy'
                : link
        } else {
          // Documents in Sanity that need a prepended value
          //
          // @NOTE: Because there doesn't seem to be a way of attaching the "document type" to a Sanity
          // reference via GROQ in this element, I've had to pick some unique fields applied to each of
          // the documents in question
          link =
            value?.type == 'hire' || value?.type == 'buy'
              ? link + `${value.type}/c/${value.slug.current}`
              : value?.author
                ? link + `blog/${value.slug.current}`
                : link + value.slug.current
        }

        return (
          <Link
            to={link}
            className="text-teal-green underline"
          >
            {children}
          </Link>
        )
      },
      link: ({ value, children }: any) => {
        if (!value?.href) return null

        return (
          <a
            href={value.href}
            target="_blank"
            rel="noreferrer"
            className="text-teal-green underline"
          >
            {children}
          </a>
        )
      },
    },
    list: {
      bullet: ({ children }: any) => (
        <ul className="mb-6 ml-5 list-disc">{children}</ul>
      ),
      number: ({ children }: any) => (
        <ol className="mb-6 ml-5 list-decimal">{children}</ol>
      ),
    },
  }

  return (
    <PortableText
      value={content}
      components={serializers}
    />
  )
}

export default ContentEditor
